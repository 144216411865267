// polyfills based on https://lit.dev/docs/tools/requirements/#loading-polyfills
import "core-js/stable"
import "regenerator-runtime/runtime"

import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'
import 'lit/polyfill-support.js'
import '@webcomponents/webcomponentsjs/webcomponents-loader.js'

import * as Sentry from '@sentry/browser';
import {ReportingObserver,HttpClient} from "@sentry/integrations";

// error reporting
const sentryOptions = {
    integrations: [new ReportingObserver({
        types: ['crash', 'intervention' /* no integrations */]
    }), new HttpClient()],
    ...SENTRY.config
};
Sentry.init(sentryOptions);

import('./main');
